import React, { Component } from 'react'
import ReactGA from 'react-ga'
import './App.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'

const TRACKING_ID = 'UA-208687930-1'
ReactGA.initialize(TRACKING_ID)

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      todaysDate: moment(),
      chosenDate: undefined,
      pillAmount: 0,
      strength: 1,
      maxDose: '',
      endDate: undefined,
      daysFromEndDate: undefined,
      remainingPills: undefined,
      daysUntilDue: undefined,
      prescriptionDate: undefined,
    }
  }

  render() {
    let {
      chosenDate,
      prescriptionDate,
      remainingPills,
      todaysDate,
      endDate,
      daysFromEndDate,
    } = this.state
    return (
      <div style={styles.page}>
        <div style={styles.wrapper}>
          <div>
            <h1>Tablettkollen</h1>
            <h3>Räkna snabbt ut nästa receptförnyelse</h3>
          </div>
          <div style={styles.inputRow}>
            <p className="listitem">Max antal tabletter/dag</p>
            <textarea
              type="number"
              style={{ ...styles.inputField, height: '50px', resize: 'none' }}
              placeholder="Dagligen enl ordination (st). t.ex. 2+0+2, 2x3"
              onChange={(e) => this.setState({ maxDose: e.target.value })}
            />
          </div>
          <div style={styles.inputRow}>
            <p className="listitem">Antal utskrivna tabletter</p>
            <input
              type="number"
              style={styles.inputField}
              placeholder="Antal (st)"
              onChange={(e) => this.setState({ pillAmount: e.target.value })}
            />
          </div>
          <div style={styles.inputRow}>
            <p className="listitem">Datum tabletter hämtades ut</p>
            <DatePicker
              dateFormat="YYYY-MM-DD"
              selected={chosenDate || todaysDate}
              onChange={this.onChangeDate}
            />
          </div>
          <button style={{ fontSize: 15 }} onClick={this.onButtonPress}>
            Räkna ut
          </button>

          {endDate ? (
            <React.Fragment>
              <p style={{ fontSize: 20 }}>
                Utifrån maxdos i förskrivningen, antal förskrivna tabletter och senaste uthämtade
                receptet bör patienten ha {remainingPills} tabletter kvar och{' '}
                <b>
                  nästa förskrivning bör ske om ca {daysFromEndDate} dagar,{' '}
                  {endDate.lang('sv').format('YYYY-MM-DD')}.
                </b>
              </p>
            </React.Fragment>
          ) : (
            <div />
          )}
          <p style={styles.credits} id="creds">
            Ett initivativ av Arvin Behshad (
            <a style={{ color: 'white' }} href="https://rvin.io">
              rvin.io
            </a>
            ) och Artin Entezarjou (
            <a style={{ color: 'white' }} href="https://drartin.se">
              drartin.se
            </a>
            )
          </p>
        </div>
      </div>
    )
  }

  onChangeDate = (date) => {
    console.log('changing date to', date)
    this.setState({ chosenDate: date })
  }

  sanitizeMaxDose = (input) => {
    if (input.includes('x')) {
      const values = input.split('x')
      let res = 1
      values.forEach((v) => {
        res = res * Number(v)
      })

      return res
    }

    if (input.includes('+')) {
      const values = input.split('+')
      let res = 0
      values.forEach((v) => {
        res = res + Number(v)
      })

      return res
    }
    return Number(input)
  }

  onButtonPress = () => {
    let {
      todaysDate,
      chosenDate,
      pillAmount,
      strength,
      maxDose: inputMaxDose,
      daysUntilDue,
    } = this.state

    ReactGA.event({
      category: 'User',
      action: 'Calculated dosage',
    })

    const maxDose = this.sanitizeMaxDose(inputMaxDose)
    if (!chosenDate) chosenDate = todaysDate
    const mChosenDate = moment(chosenDate)
    let daysSince = moment().diff(mChosenDate, 'days')
    let totalDose = pillAmount * strength
    let daysSupply = totalDose / maxDose
    let endDate = mChosenDate.add(daysSupply, 'days')
    endDate = mChosenDate.subtract(1, 'days')
    let daysFromEndDate = Math.abs(moment().diff(endDate, 'days')) + 1

    // stage 2
    let pillsPerDay = maxDose / strength
    let remainingPills = pillAmount - pillsPerDay * daysSince
    let mEndDate = moment(endDate)
    let prescriptionDate = mEndDate.subtract(daysUntilDue, 'days')
    this.setState({ endDate, daysFromEndDate, remainingPills, prescriptionDate })
  }
}

//tab/ask * styrka

const styles = {
  page: {
    display: 'flex',
    margin: 10,
    flex: 1,
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    maxWidth: '500px',
    margin: 'auto',
    height: 'auto',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'space-between',
  },
  inputField: {
    width: '200px',
  },
  credits: {
    fontStyle: 'italic',
    fontSize: 12,
    marginTop: 60,
  },
}

export default App
